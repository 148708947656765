import React from 'react'
import { CircularProgress, Grid, Typography } from '@material-ui/core'
// import { withStyles } from '@material-ui/core/styles'
import { RecentActivity } from '../RecentActivity'
import { NeedsReview } from '../NeedsReview'
import ReferralStatusBar from './referralStatusBar'
import { useAuth } from '../../providers/Auth'
// import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import {
  useFrameHeightLock,
  useSetFrameHeaderLeft,
  useSetFrameHeaderMid,
} from '../AppFrame'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import useTourDriver from '../../hooks/useTourDriver'

const StyledStatusBarHeader = styled.div`
  .referral-status-bar {
    display: flex;
    align-items: center;
    text-align: center;

    a.item {
      position: relative;
      padding: 4px 10px;
      border-right: 1px dotted rgba(0, 0, 0, 0.35);
      color: inherit;
      text-decoration: none;
      box-sizing: border-box;
      display: block;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 100%;
        left: 2px;
        right: 2px;
        bottom: 0;
        background: rgba(0, 0, 0, 0.12);
        transition: all 0.2s ease-in-out;
        border-radius: 5px;
      }

      &:last-of-type {
        border-right: none;
      }

      div {
        display: inline-flex;
        align-items: center;
        column-gap: 0.25rem;

        i {
          font-size: 120%;
          line-height: 0;
          top: 1px;
          position: relative;
        }
      }

      span {
        display: block;
      }

      &:hover {
        &::before {
          top: 0;
        }
      }
    }
  }
`

const StyledDashContent = styled.div`
  display: flex;
  // column-gap: 2rem;
  // overflow-y: scroll;
  height: 100%;

  .activity-list-card-root {
    background: transparent;
    box-shadow: none;
    padding: 0;

    .activity-list-card-header {
      padding: 0;
      margin-bottom: 0.5rem;
    }

    .activity-list-card-content {
      padding: 0;
    }
  }

  .wrap-needs-review {
    box-sizing: border-box;
    width: 75%;
    height: 100%;
    background: rgb(241, 241, 241);
    padding: 1rem 2rem;
    overflow-y: scroll;
    box-shadow: -16px 0px 26px -30px inset;
  }

  .wrap-activities {
    width: 25%;
    height: 100%;
    overflow-y: scroll;
    padding: 1rem 2rem;
    box-sizing: border-box;
  }
`

export default function Dashboard() {
  const { access } = useAuth()
  const hasPerms = React.useMemo(() => {
    return (
      access.CanAccessInvoices ||
      access.CanAccessReferrals ||
      access.CanManageUsers ||
      access.CanViewOrgClaimsReport ||
      access.CanViewOrgQuarterlyReport
    ) // case: report-only viewers
  }, [access])

  const tourDriver = useTourDriver('dashboard')
  const tourHeader = tourDriver.addStep({
    key: tourDriver.makeKey(),
    side: 'bottom',
  })
  tourDriver.addStep({
    key: tourDriver.makeKey(),
    selector: '#tour-marker-fast-actions',
    side: 'right',
    title: 'Fast Actions',
    descr: 'Common actions are available here for quick access.',
  })

  useFrameHeightLock(() => true, [])

  useSetFrameHeaderLeft(
    () => (
      <div
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          columnGap: '0.5rem',
        }}>
        <Typography variant="h5">Dashboard</Typography>
        <tourDriver.BtnReplayTour />
      </div>
    ),
    [tourDriver]
  )

  useSetFrameHeaderMid(() => {
    if (!access || !hasPerms) return null
    return (
      <StyledStatusBarHeader
        ref={tourHeader.ref}
        data-tour-title="Referrals Snapshot"
        data-tour-descr="Quickly glance at an overview of Referrals and Requests relevant to you.">
        <ReferralStatusBar
          RenderComponent={(props: any) => {
            return (
              <Link to={props.linkToParams} className="item">
                <div>
                  <i>{props.icon}</i>
                  <strong>{props.Amount}</strong>
                </div>
                <span>{props.Status}</span>
              </Link>
            )
          }}
        />
      </StyledStatusBarHeader>
    )
  }, [access, hasPerms])

  if (!access) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} />
        <Grid item xs={4} />
        <Grid item xs={4}>
          <CircularProgress />
          <Typography
            style={{ display: 'inline-block', marginLeft: 10 }}
            variant="body1">
            Redirecting you to login...
          </Typography>
        </Grid>
      </Grid>
    )
  }

  if (!hasPerms) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} />
        <Grid item xs={12}>
          <Typography variant="h5">
            You are missing necessary permissions in order to use goZERO.
          </Typography>
          <br />
          <Typography variant="body1">
            If you have a local goZERO administrator, please contact them to
            receive permissions.
          </Typography>
          <Typography variant="body2">
            If you are an administrator or are continuing to have issues, please
            notify your ZERO contact or message us at{' '}
            <a href="mailto:providers@zero.health">providers@zero.health</a>
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <StyledDashContent>
      {access.CanAccessReferrals && (
        <div className="wrap-needs-review">
          <NeedsReview />
        </div>
      )}
      {access.CanAccessReferrals && (
        <div className="wrap-activities">
          <RecentActivity />
        </div>
      )}
    </StyledDashContent>
    // <Grid container spacing={2}>
    //   <Grid item xs={6}>
    //     {access.CanAccessReferrals && <NeedsReview />}
    //   </Grid>
    //   <Grid item xs={6}>
    //     {access.CanAccessReferrals && <RecentActivity />}
    //   </Grid>
    // </Grid>
  )
}
