import moment from 'moment'
import isEmail from 'is-email'
import * as T from './types'
import { Validators } from '../../hooks/useForm'
import { isValidCpt } from '../../utils'

const parsePhone = (phoneNr: string | null) => {
  if (phoneNr === '') phoneNr = null
  if (phoneNr && typeof phoneNr === 'string') {
    phoneNr = phoneNr.replace(/\D/g, '') // all non digits with ''
  }
  return phoneNr
}

const validatePhone = (val: any) => {
  if (!val) return true // phone can be blank
  const parsed = parsePhone(val)
  return !!(
    (parsed && parsed.length === 10) ||
    (parsed && parsed.length === 11 && parsed[0] === '1')
  )
}

const isInt = (value: any) =>
  !isNaN(value) && parseInt(value) == value && !isNaN(parseInt(value, 10))

const validatePhoneExt = (val: any) => {
  if (!val) return true // ext can be blank
  return !!(isInt(val) && val.length <= 4)
}

const validateEmail = (val: any) => {
  if (!val) return true // email can be blank
  return !!val && isEmail(val)
}

const validators: Validators<T.ReferralFormData> = {
  ContactEmail: {
    isValid: validateEmail,
    msg: 'Contact email must either be blank, or a valid email',
  },
  ContactPhone: {
    isValid: validatePhone,
    msg: 'Contact phone must either be blank, or a valid phone number',
  },
  ContactPhoneExt: {
    isValid: validatePhoneExt,
    msg: 'Contact ext. must either be blank, or a number less than four digits long',
  },
  Descr: { isValid: (val) => !!val, msg: 'Description is required' },
  MainCptCode: {
    isValid: (val) => {
      if (!val) return true
      return !!val && !!isValidCpt(val)
    },
    msg: 'Main Cpt Code must either be blank, or a valid cpt',
  },
  OrderingPhysEmail: {
    isValid: validateEmail,
    msg: 'Ordering physician email must either be blank, or a valid email',
  },
  OrderingPhysFax: {
    isValid: validatePhone,
    msg: 'Ordering physician fax must either be blank, or a valid phone number',
  },
  OrderingPhysPhone: {
    isValid: validatePhone,
    msg: 'Ordering physician phone must either be blank, or a valid phone number',
  },
  OrderingPhysPhoneExt: {
    isValid: validatePhoneExt,
    msg: 'Phone extension must be blank, or a number less than four digits long',
  },
  OrganizationID: {
    isValid: (val) => !!val,
    msg: 'You must pick a organization',
  },
  PracticeFacilityID: {
    isValid: (val) => !!val,
    msg: 'You must pick a facility',
  },
  ServiceDate: {
    isValid: (val, data) => {
      if (!val) return true

      const m = val && moment(val)

      if (m && m.isValid()) {
        const yearsPast = moment().subtract(10, 'year')
        const yearsFuture = moment().add(10, 'year')
        const tooFarInThePast = m.isBefore(yearsPast)
        const tooFarInTheFuture = m.isAfter(yearsFuture)
        let withinEligibility = true

        if (data?.EmployerMember?.ActivationDate) {
          const memberActivation = moment(data.EmployerMember.ActivationDate)
          const memberDeactivation = moment(
            data.EmployerMember.DeactivationDate
          )
          withinEligibility =
            m.isSameOrAfter(memberActivation) &&
            (memberDeactivation.isValid()
              ? m.isBefore(memberDeactivation)
              : true)
        }

        return !(tooFarInThePast || tooFarInTheFuture) && withinEligibility
      }

      return false
    },
    msg: 'Service Date is outside of known eligibility dates for the member. Note: during plan renewal windows (i.e. frequently end of calendar year), ZERO does not always receive timely eligibility updates, thus setting service dates in the next year may be invalid. If you are unable to set the service date, please leave the field empty and tag a ZERO member in chat.',
  },
  StatusID: { isValid: (val) => !!val, msg: 'You must pick a status' },
}

export default validators
