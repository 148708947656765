/*
Example code for using this hook below. Note the super verbose comments about how you
can pass 'callback'; reason being we'd prefer to not have a million different experiments
out in the wild. IOW - unless you _really_ understand the full chain of things feeding into
the usePolling hook, you should always being defining callback with a React.useCallback.

import usePolling from '...'

export default function MyComponent() {
  const [params, setParams] = useState({
    sort: 'created_at',
    other: true,
    // etc, etc...
  })
  const { data = [], refetch } = useApi(
    {
      route: '/activity/needs_review',
      ...params
    }
  )

  const { startPolling, stopPolling } = usePolling({
    duration: 5000, // or omit to default to 7500

    // bad (but works): in this case, refetch actually returns a promise (which is
    // required by the poller API), but refetch will be recreated on every render,
    // which means its "unstable" and causes lots of churn (unnecessary re-renders)
    callback: refetch,

    // good: in this case we stabilize the refetch function by wrapping it in a
    // useCallback, thus re-renders are reduced, but you still need to know the internals
    // of refetch to know that it returns a promise
    callback: React.useCallback(() => { return refetch(params) }, [params])

    // best: same as above, but we return our own Promise, which means the internals of
    // whatever the hell you want to call inside it don't matter, and you can resolve()
    // on your own timing/terms. This is the most flexible.
    callback: React.useCallback(() => {
      return new Promise(async (resolve, reject) => {
        await refetch(params)
        resolve(null)
      })
    }, [params])
  })
}
*/
import { useCallback, useEffect, useId, useState } from 'react'
import useSnackbar, { SnackbarTypeError } from './useSnackbar'

interface options {
  duration?: number
  callback?(): Promise<any>
  autoStopPollingAfterMins?: number
}

const timerStore = new Map()

export default function usePolling({
  duration = 2500,
  callback,
  autoStopPollingAfterMins = 120, // 2 hours
}: options) {
  const id = useId()
  const [running, setRunning] = useState(true)
  const [initdAt] = useState(new Date().valueOf()) // unix timestamp
  const { show: showSnackbar } = useSnackbar()

  /*
    doPolling is a recursive function that calls itself ("replay") after the
    duration has passed. To ensure the timerStore map only has a single timer
    scheduled at a time (**per invocationg of the hook!**), whenever doPolling is called,
    the very first thing it does is clear any existing timeouts. (If you get into scheduling
    setTimeouts in any other area of this hook, you'll lose track/references to previously
    scheduled ones, and when the hook unmounts, there'll be no way to cancel it later).

    Anyways - after clearing any existing timeouts, we immediately schedule a new one
    and store its ID in the timerStore map... Once the callback completes (which MUST
    BE A PROMISE), we then check if the timerStore has the ID in it (which if it doesn't,
    it means it was STOPPED), and if so, we call replay. This repeats itself for the
    duration of the hook's lifetime, while 'running' is true.

    Further: useCallback here is *very* important to ensure that the function is using
    the latest dependencies, AND this is what makes it so that the doPolling function,
    when passed as a dependency to the useEffect below, re-triggers appropriately.
  */
  const doPolling = useCallback(
    function replay() {
      // if running state false, bail immediately
      if (!running) return
      // if initdAt is older than 1 hour, automatically cancel the polling (
      // use has probably left the page open for a long time and walked away)
      const diffFromInit = new Date().valueOf() - initdAt
      if (diffFromInit > autoStopPollingAfterMins * 60 * 1000) {
        stopPolling()
        showSnackbar(
          'This page has stopped automatically updating. Please refresh.',
          SnackbarTypeError
        )
        return
      }
      clearTimeout(timerStore.get(id))
      timerStore.set(
        id,
        setTimeout(() => {
          callback?.().then(() => {
            timerStore.has(id) && replay()
          })
        }, duration)
      )
    },
    [id, duration, callback, running]
  )

  /*
    When the hook mounts and either 'doPolling' function (which importantly, is a useCallback
    function - so its stable when passing to useEffect's array of dependencies), OR 'running'
    changes, we act accordingly.
  */
  useEffect(() => {
    if (!running) {
      stopPolling()
      return
    }
    doPolling()
  }, [doPolling, running])

  /*
    The only purpose of this useEffect is to register its' return function, which cleans up
    and kills any outstanding timers.
  */
  useEffect(() => {
    return stopPolling
  }, [])

  /*
    All this does is set running state to true, which triggers the useEffect above to start
  */
  function startPolling() {
    setRunning(true)
  }

  /*
    This is the function that actually stops the polling. It clears any existing timers in
    the store, sets running to false, and importantly, clears the timerStore of the ID
  */
  function stopPolling() {
    setRunning(false)
    clearTimeout(timerStore.get(id))
    timerStore.delete(id)
  }

  return {
    startPolling,
    stopPolling,
    running,
  }
}

/*
THIS IS OK AND WORKS, but uses intervals instead of timeouts. Downside of that approach
is: if the api request takes 5 seconds, and the interval is set to 4 seconds, it'll look
like this:
  Poll[1,2,3,4] Poll[1,2,3,4] Poll[1,2,3,4]
  |req--------------|done
                |req----------------------|done (longer than expected response)
                              |req--------------|done
In other words, calls will stomp on each other. (timing above for example only)

With timeouts though, we can wire it to queue the next timeout **only after the previous
one finishes completely**. So if for some reason one request took 10 seconds (say heavy server
load), it will wait for that one to finish, then the next request will still be queued
for the full duration of the delay.
  Poll[1,2,3,4] Poll[1,2,3,4]             Poll[1,2,3,4]
  |req---|done
                |req----------------------|done (longer than expected response)
                                          |req---|done

This is here for example purposes if we ever need it in the future (can't think of why though).
*/
// export default function usePolling({duration = 5000, callback}: options) {
//   const [tick, setTick] = React.useState(0)
//   const [poller, setPoller] = React.useState<any>(null)

//   // On hook being mounted, start polling
//   React.useEffect(() => {
//     startPolling()
//     return stopPolling
//   }, [])

//   // On tick change, call callback
//   React.useEffect(callback, [tick])

//   // start polling
//   function startPolling() {
//     stopPolling()
//     setPoller(setInterval(() => {
//       setTick((t:number) => t + 1)
//     }, duration))
//   }

//   // stop polling
//   function stopPolling() {
//     clearInterval(poller)
//   }

//   return {
//     startPolling,
//     stopPolling
//   }
// }
