import React from 'react'
import { Link } from 'react-router-dom'
import { Add as ContentAdd } from '@material-ui/icons'
import { Button } from '@material-ui/core'
import { useAuth } from '../../providers/Auth'

interface Props {
  stackButtons?: boolean
  ButtonProps?: any
}

const AddNewReferralBtn: React.FC<Props> = ({
  stackButtons,
  ButtonProps,
}: Props) => {
  const { access } = useAuth()

  const canAddReferral = access.CanCreateReferrals
  const canAddReferralRequest = access.CanCreateReferralRequests

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: stackButtons ? 'column' : 'row',
        columnGap: '8px',
        rowGap: '8px',
      }}>
      {canAddReferral && (
        <Button
          variant="outlined"
          to="/find_member?formType=referral"
          component={Link}
          startIcon={<ContentAdd />}
          {...ButtonProps}>
          New Referral
        </Button>
      )}

      {canAddReferralRequest && (
        <Button
          variant="outlined"
          to="/find_member?formType=referralRequest"
          component={Link}
          startIcon={<ContentAdd />}
          {...ButtonProps}>
          Request Referral
        </Button>
      )}
    </div>
  )
}

export default AddNewReferralBtn
