import React, { useContext, useEffect, useState } from 'react'
import createAuth0Client, { Auth0Client } from '@auth0/auth0-spa-js'
import { getUser } from '../actions/UserActions'
import { useConfig } from './Config'
import AuthError, {
  PASSWORD_EXPIRED,
  UNKNOWN_ERROR,
} from '../components/AuthError'
import LoadingIndicator from '../components/LoadingIndicator'

const localStorage = window.localStorage

export interface Access {
  CanManageUsers: boolean
  CanAccessReferrals: boolean
  CanCreateReferrals: boolean
  CanCreateLOAs: boolean
  CanAccessInvoices: boolean
  CanCreateReferralRequests: boolean
  CanViewOrgClaimsReport: boolean
  CanViewOrgQuarterlyReport: boolean
  IsZeroUser: boolean
  Roles: {
    ReferralViewer: boolean // this will be removed
    ReferralCoordinator: boolean
    ReferralAdministrator: boolean
    ReferralInvoiceReviewer: boolean
    ReferralInvoiceSubmitter: boolean
    ReferralRequester: boolean
    OrganizationReportsViewer: boolean
    ReferrerReportsViewer: boolean
  }
}

interface AuthContext {
  access: Access
  idToken: string
  logout: any
  popupOpen: boolean
  user: any
  zeroUser: any
  zeroUserProfile: any
  authenticated: boolean
}

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

export const AuthContext = React.createContext({} as AuthContext)
export const useAuth = (): AuthContext => useContext(AuthContext)

export const AuthProvider: React.FC<any> = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [access, setAccess] = useState<Access>()
  const [user, setUser] = useState<any>()
  const [auth0Client, setAuth0] = useState<Auth0Client>()
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState(true)
  const [popupOpen] = useState(false)
  const [zeroUser, setZeroUser] = useState(null)
  const [zeroUserProfile, setZeroUserProfile] = useState(null)
  const [idToken, setIdToken] = React.useState<string>()
  const [authenticated, setAuthenticated] = useState(false)
  const { auth0ClientID, auth0Domain }: any = useConfig()

  const onPasswordExpired = () => {
    setAuthenticated(true)
    setLoading(false)
    setError(PASSWORD_EXPIRED)
  }

  const onError = () => {
    setLoading(false)
    setError(UNKNOWN_ERROR)
  }

  const initAuth0 = async () => {
    const auth0FromHook = await createAuth0Client({
      client_id: auth0ClientID,
      domain: auth0Domain,
      ...initOptions,
    })
      .then((client) => client)
      .catch((err) => {
        // this handles the silent auth flow...ie if you are already identified by another application
        const { error_description } = err
        if (error_description === PASSWORD_EXPIRED) {
          return onPasswordExpired()
        }
        return onError()
      })
    if (!auth0FromHook) return // if it does not resolve, its likely an error condition (handled above)

    // when we get login info from auth0, navigate into the app
    if (
      window.location.search.includes('code=') &&
      window.location.search.includes('state=')
    ) {
      const { appState } = await auth0FromHook.handleRedirectCallback()
      onRedirectCallback(appState)
    }

    // when we get error info from auth0, handle it
    if (window.location.search.includes('error=')) {
      if (
        window.location.search.includes(
          `error=unauthorized&error_description=${PASSWORD_EXPIRED}`
        )
      ) {
        return onPasswordExpired()
      }
      return onError()
    }

    const isAuthenticated = await auth0FromHook.isAuthenticated()
    setAuthenticated(isAuthenticated)

    if (isAuthenticated) {
      setError(null)
      // we can make this super idiomatic with access_tokens requested for each api call
      // but for now we're swiping the ID Token to seamlessly integrate with our backend.
      const token = await auth0FromHook.getTokenSilently()
      const claims = await auth0FromHook.getIdTokenClaims()
      const user = await auth0FromHook.getUser()
      const rawClaims = claims && claims.__raw

      localStorage.setItem('access_token', token)
      localStorage.setItem('claims', JSON.stringify(claims))
      rawClaims && localStorage.setItem('id_token', rawClaims)

      // this relies on an id_token being set in localstorage
      const userInfo = await getUser()
      localStorage.setItem('userInfo', JSON.stringify(userInfo))

      setUser(user)
      setZeroUser(userInfo)
      setZeroUserProfile(userInfo)
      rawClaims && setIdToken(rawClaims)
      setAccess(userInfo.Access)
      setLoading(false)
    } else {
      await auth0FromHook.loginWithRedirect({
        appState: { targetUrl: window.location.pathname },
      })
    }

    setAuth0(auth0FromHook)
    setLoading(false)
  }

  useEffect(() => {
    // wait for the config to initialize both of these values before continuing.
    if (!auth0ClientID || !auth0Domain) {
      return
    }

    initAuth0()
  }, [auth0ClientID, auth0Domain])

  if (error) {
    return <AuthError error={error} />
  }

  if (loading || !auth0Client || !access || !idToken) {
    const inlineStyle = {
      height: '100%',
      marginTop: '15px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }

    return (
      <div style={inlineStyle}>
        <LoadingIndicator />
      </div>
    )
  }

  return (
    <AuthContext.Provider
      value={{
        access,
        user,
        popupOpen,
        zeroUserProfile,
        zeroUser,
        idToken,
        authenticated,
        logout: (...p: any) => auth0Client.logout(...p),
      }}>
      {children}
    </AuthContext.Provider>
  )
}
