import React, { useState } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Tooltip,
  Typography,
} from '@material-ui/core'
import {
  InfoOutlined,
  Launch as IconLaunch,
  CheckCircleOutline as IconCheckCircle,
} from '@material-ui/icons'
import { Skeleton } from '@material-ui/lab'
import { withStyles } from '@material-ui/core/styles'
import { ReferralForm } from './types'
import ReferralFileUpload from './ReferralFileUpload'
import SelectPracticeFacility from './SelectPracticeFacility'
import SelectOrganization from './SelectOrganization'
import SelectField from '../SelectField'
import SwitchField from '../SwitchField'
import TextField from '../TextField'
import ReferrerSelect from '../ReferrerSelect'
import ReferrerDivisionSelect from '../ReferrerDivisionSelect'
import useApi from '../../hooks/useApi'
import { useAuth } from '../../providers/Auth'
import Backlink from '../Backlink'
import CancelDeclineDialog from './CancelDeclineDialog'
import referralStatuses from './statuses'
import ManagedDateInput from '../Inputs/managedDateInput'

interface Props {
  form: ReferralForm
  showErrors: boolean
  isEditing?: boolean
}

const TooltipTitle = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin: 0px 0px 0px 0px;
  text-transform: uppercase;
`

const SubText = styled.p`
  margin: 0px;
`

const TooltipText = styled.p`
  margin-top: 0px;
`

const LOAContainer = styled(Grid)`
  padding-left: 10px;
  padding-bottom: 20px;
`

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}))(Tooltip)

const StatusTooltipContent = () => {
  return (
    <div style={{ padding: '15px 10px 10px 10px' }}>
      <TooltipTitle>New</TooltipTitle>
      <TooltipText>
        New referral that the provider can take forward.
      </TooltipText>

      <TooltipTitle>Received</TooltipTitle>
      <TooltipText>Provider marks this when viewed.</TooltipText>

      <TooltipTitle>Accepted</TooltipTitle>
      <TooltipText>Provider marks when accepted.</TooltipText>

      <TooltipTitle>Declined</TooltipTitle>
      <TooltipText>When provider needs to decline a referral.</TooltipText>

      <TooltipTitle>Cancelled</TooltipTitle>
      <TooltipText>When a member requests to cancel.</TooltipText>

      <TooltipTitle>Scheduled</TooltipTitle>
      <TooltipText>Provider marks when member is scheduled.</TooltipText>

      <TooltipTitle>Completed</TooltipTitle>
      <TooltipText>Provider marks when service is complete.</TooltipText>
    </div>
  )
}

const renderCancellationEdit = (form: ReferralForm) => {
  const [showModal, setShowModal] = useState<boolean>(false)

  const displayReason = () => {
    if (form.data.StatusID === referralStatuses.REFERRAL_STATUS_DECLINED)
      return form.data.DeclineReason || ''
    if (form.data.StatusID === referralStatuses.REFERRAL_STATUS_CANCELLED)
      return form.data.CancelReason || ''
    return ''
  }

  return (
    <>
      <CancelDeclineDialog
        showModal={showModal}
        setShowModal={setShowModal}
        form={form}
        submit={() => setShowModal(false)}
        status={form.data.StatusID}
      />
      <Grid container direction="column" style={{ paddingTop: '1rem' }}>
        <TextField
          multiline
          disabled={true}
          onChange={() => null}
          label="Reason"
          value={displayReason()}
        />
        <Button onClick={() => setShowModal(true)} color="primary">
          Update Reason
        </Button>
      </Grid>
    </>
  )
}

const SelectStatus: React.FC<any> = ({
  statusOptions,
  form,
  showErrors,
  readOnly,
  // useV2Style = false,
}) => {
  return statusOptions ? (
    <Grid container>
      <Grid container alignItems="center">
        <SelectField
          label="Status"
          value={form.data.StatusID}
          options={statusOptions}
          onChange={form.setters.StatusID}
          error={showErrors ? form.errors.StatusID : null}
          disabled={readOnly}
          // useV2Style={useV2Style}
        />
        <LightTooltip title={<StatusTooltipContent />}>
          <InfoOutlined style={{ fontSize: '16px' }} />
        </LightTooltip>
      </Grid>
      {(form.data.StatusID === referralStatuses.REFERRAL_STATUS_CANCELLED ||
        form.data.StatusID === referralStatuses.REFERRAL_STATUS_DECLINED) &&
        renderCancellationEdit(form)}
    </Grid>
  ) : (
    <Skeleton />
  )
}

interface RequestLinkProps {
  referralRequestId: number | null
  referrerName: string
  divisionName: string
}

const ReferralRequestLink: React.FC<RequestLinkProps> = ({
  referralRequestId,
  referrerName,
  divisionName,
}: RequestLinkProps) => {
  // in this case we have a referral that was created from a referral request,
  // we can link to that referral request
  if (!referralRequestId) return null
  const string = divisionName
    ? `View original referral request from ${referrerName} / ${divisionName}`
    : `View original referral request from ${referrerName}`
  return (
    <Backlink to={`/referral_request/${referralRequestId}`}>{string}</Backlink>
  )
}

const ReferralDetailsForm: React.FC<Props> = ({
  form,
  showErrors,
  isEditing,
}: Props) => {
  const [selectedOrgNotes, setSelectedOrgNotes] = useState<string>('')
  const isArchived = !!form.data.ArchivedAt
  const { access } = useAuth()
  const { data: statuses } = useApi({
    route: '/referral_statuses',
  })
  const statusOptions = statuses
    ? statuses.map((s: any) => ({ name: s.Descr, value: s.ID }))
    : []

  const statusDesc = statuses
    ? (
        statuses.find((s: any) => {
          if (!form.data.StatusID) return false
          return s.ID === form.data.StatusID
        }) || { Descr: '' }
      ).Descr
    : ''

  const renderCreatedByMessage = () => {
    if (!form.data.CreatedAt || !form.data.CreatedByUser) return null
    return (
      <SubText>
        Created by {form.data.CreatedByUser.FirstName}{' '}
        {form.data.CreatedByUser.LastName}{' '}
        {moment(form.data.CreatedAt).calendar()}
      </SubText>
    )
  }

  const renderArchivedAt = () => {
    if (!form.data.ArchivedAt) return null
    return (
      <SubText>Archived on {moment(form.data.ArchivedAt).calendar()}</SubText>
    )
  }

  const zendeskLinkURL = `https://tzc.zendesk.com/agent/tickets/${form.data.TicketNr}`
  const hasBeenInvoiced = !!form.data.ReferralInvoiceID

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
          <Typography variant="h5">
            <span className="header-snazzy">Referral Details</span>
          </Typography>

          <div style={{ marginLeft: '1rem' }}>
            <SwitchField
              label="Urgent"
              value={!!form.data.IsUrgent}
              onChange={form.setters.IsUrgent}
              disabled={!form.data.Edit || isArchived}
            />
          </div>
          <div style={{ marginLeft: '1rem' }}>
            <SwitchField
              label="Is Traveling"
              value={!!form.data.IsTravel}
              onChange={form.setters.IsTravel}
              disabled={!form.data.Edit || isArchived}
            />
          </div>
          <div style={{ marginLeft: '1rem' }}>
            <SwitchField
              label="Is Surgery"
              value={!!form.data.IsSurgery}
              onChange={form.setters.IsSurgery}
              disabled={!form.data.Edit || isArchived}
            />
          </div>
        </div>

        {hasBeenInvoiced && (
          <span
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              color: 'green',
              padding: '0 0.5rem',
            }}>
            Invoiced&nbsp;
            <IconCheckCircle fontSize="inherit" />
          </span>
        )}
      </div>
      <Typography variant="caption">
        <>
          {renderCreatedByMessage()}
          {renderArchivedAt()}
          {(access.Roles.ReferralRequester || access.IsZeroUser) && (
            <ReferralRequestLink
              referralRequestId={form.data.ReferralRequestID}
              referrerName={form.data.ReferrerName || ''}
              divisionName={form.data.ReferrerDivisionName || ''}
            />
          )}
        </>
      </Typography>

      {access.IsZeroUser && !!form.data?.Loas?.length ? (
        <LOAContainer container xs={12} spacing={2} direction="column">
          <Typography>Linked LOAs:</Typography>
          {form.data.Loas.map((loa) => {
            return (
              <a
                href={`https://admin.zero.health/loa/${loa.ID}`}
                target="_blank">
                {`CPT: ${loa.MainCptCode} - Date of Service: ${loa.DateOfService}`}
              </a>
            )
          })}
        </LOAContainer>
      ) : null}

      <hr style={{ marginTop: '1rem' }} />

      <div style={{ marginBottom: '1.5rem' }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            {!!form.data?.EmployerMember?.EmployerID && (
              <SelectOrganization
                useV2Style
                form={form}
                showErrors={showErrors}
                readOnly={isEditing}
                withSelectedOrgNotes={(notes: string | null) => {
                  setSelectedOrgNotes(notes || '')
                }}
              />
            )}
          </Grid>
          <Grid item xs={4}>
            <SelectPracticeFacility
              useV2Style
              form={form}
              showErrors={showErrors}
              readOnly={!form.data.Edit || isArchived}
            />
          </Grid>
          <Grid item xs={4}>
            <SelectStatus
              useV2Style
              statusOptions={statusOptions}
              form={form}
              showErrors={showErrors}
              readOnly={!form.data.Edit || isArchived}
            />
          </Grid>
        </Grid>

        {selectedOrgNotes && (
          <div
            style={{
              background: '#f1f1f1',
              margin: '0.5rem 0 0',
              padding: '0.75rem',
              color: '#777',
              borderRadius: '8px',
            }}>
            <strong style={{ display: 'block', fontSize: '85%' }}>
              Organization Notes
            </strong>
            <span>{selectedOrgNotes}</span>
          </div>
        )}
      </div>

      <Grid container spacing={2} style={{ marginBottom: '1.5rem' }}>
        <Grid item xs={4}>
          <TextField
            label="Description"
            value={form.data.Descr || ''}
            onChange={form.setters.Descr}
            error={showErrors ? form.errors.Descr : null}
            disabled={!form.data.Edit || isArchived}
          />
        </Grid>
        <Grid item xs={3}>
          <ManagedDateInput
            label="Service Date"
            value={form.data.ServiceDate}
            setter={({ name, value }) => form.setters.ServiceDate(value)}
            error={showErrors ? form.errors.ServiceDate : null}
            disabled={!form.data.Edit || isArchived}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            label="Main CPT"
            value={form.data.MainCptCode || ''}
            onChange={form.setters.MainCptCode}
            error={showErrors ? form.errors.MainCptCode : null}
            disabled={!form.data.Edit || isArchived}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label="Servicing Provider"
            value={form.data.ServicingProvider || ''}
            onChange={form.setters.ServicingProvider}
            disabled={!form.data.Edit || isArchived}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} style={{ marginBottom: '1.5rem' }}>
        <Grid item xs={12}>
          <TextField
            label="Notes"
            value={form.data.Notes || ''}
            onChange={form.setters.Notes}
            disabled={!form.data.Edit || isArchived}
            multiline
            minRows={3}
          />
        </Grid>
      </Grid>

      {access.IsZeroUser && (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            {!!form?.data?.EmployerMember?.EmployerID && (
              <ReferrerSelect
                form={form}
                readOnly={!form.data.Edit || isArchived}
              />
            )}
            {form.data.ReferrerID && (
              <div style={{ paddingTop: '1rem' }}>
                <ReferrerDivisionSelect
                  value={form.data.ReferrerDivisionID}
                  onChange={form.setters.ReferrerDivisionID}
                  referrerID={form.data.ReferrerID}
                />
              </div>
            )}
          </Grid>

          <Grid item xs={3}>
            <TextField
              label="Zendesk Ticket #"
              value={form.data.TicketNr || ''}
              onChange={form.setters.TicketNr}
              disabled={!form.data.Edit || isArchived}
              InputProps={{
                endAdornment: !!form.data.TicketNr && (
                  <InputAdornment position="end">
                    <IconButton
                      href={zendeskLinkURL}
                      target="_blank"
                      rel="noopener noreferrer"
                      edge="end">
                      <IconLaunch />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      )}

      <hr />
      {form.data.ID ? (
        <ReferralFileUpload
          referralId={form.data.ID}
          disabled={!form.data.Edit}
        />
      ) : (
        <span>*NOTE* You will be able upload files after saving</span>
      )}
    </div>
  )
}

export default ReferralDetailsForm
